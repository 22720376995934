
import mediaHelper from "@libraryHelpers/media.js";
export default {
	name: "CoreBlockHero",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dataUrl: null,
		};
	},
	computed: {
		background() {
			if (this.mediaIsImg) {
				return {
					"background-image": `url('${this.croppedSrc}')`,
					"background-position": this.mediaBackgroundPosition,
					"background-repeat": "no-repeat",
					"background-size": "cover",
				};
			}
			return null;
		},
		childBlockComponent() {
			if (this.isEditable) {
				return "ForgeControlBlock";
			}
			return "CoreBlock";
		},
		blockId() {
			return this.settings.id;
		},
		variants() {
			return this.settings.variants || {};
		},
		imageAlign() {
			return (this.variants["image-align"] || "left").replace(
				"image-",
				""
			);
		},
		textAlign() {
			return (this.variants["text-align"] || "left").replace("text-", "");
		},
		verticalAlign() {
			return this.variants["vertical-align"] || null;
		},
		mediaIsVideo() {
			return !!this.mediaSrc && mediaHelper.isVideo(this.mediaSrc);
		},
		mediaIsImg() {
			return !!this.mediaSrc && mediaHelper.isImage(this.mediaSrc);
		},
		mediaSrc() {
			const mediaSrc = this.settings?.backgroundMediaSrc;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		croppedSrc() {
			return this.mediaMetadataBackground?.image
				? this.dataUrl
				: mediaHelper.getEncodedURI(this.mediaSrc);
		},
		mediaBackgroundPosition() {
			return this.backgroundMediaFocal?.bgPosition || "center";
		},
		backgroundMediaFocal() {
			const background = this.settings?.backgroundMediaSrc;
			const mediaSrc =
				background && typeof background === "string"
					? this.settings?.mediaMetadata?.backgroundMediaSrc
					: this.settings?.backgroundMediaSrc;
			return mediaSrc?.focal;
		},
		sideMediaSrc() {
			const mediaSrc = this.settings?.sideMediaSrc;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		settingsObj() {
			return this.settings;
		},
		mediaAlt() {
			const mediaSrc = this.settings?.backgroundMediaSrc;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.alt
				: this.settings?.alt;
		},
		mediaMetadataBackground() {
			const background = this.settings?.backgroundMediaSrc;
			const mediaSrc =
				background && typeof background === "string"
					? this.settings?.mediaMetadata?.backgroundMediaSrc
					: this.settings?.backgroundMediaSrc;
			if (!mediaSrc) return background;
			const { crop } = mediaSrc;
			return crop ? crop : mediaSrc;
		},
		mediaMetadataSide() {
			const mediaSrc = this.settings?.sideMediaSrc;
			const { crop, focal } = mediaSrc;
			if (crop || focal) {
				const mediaObject = { src: crop };
				if (focal) mediaObject.src.focal = focal;
				return mediaObject;
			}
			return { src: this.settings?.mediaMetadata?.sideMediaSrc };
		},
	},
	methods: {
		getSettings() {
			const response = {
				type: "columns",
				"column-count": 2,
				variants: {
					layout: "grid",
					"vertical-alignment": this.verticalAlign,
					"image-alignment": this.imageAlign,
					"padding-top": "pt-small",
					"bg-color": "transparent",
					// layout: "contained",
				},
				locked: true,
				blocks: [],
			};
			const imageBlock = {
				type: "rows",
				blocks: [],
				variants: {
					"bg-color": "transparent",
				},
			};
			if (this.sideMediaSrc) {
				imageBlock.blocks.push({
					id: `i1_${this.settings.id}`,
					type: "media",
					acceptTypes: ["video", "image"],
					variants: {
						sizing: "contain",
						"bg-color": "transparent",
					},
					src: this.sideMediaSrc,
					width: "100%",
					height: "auto",
					mediaMetadata: this.mediaMetadataSide,
				});
			}
			response.blocks.push(imageBlock);
			const contentBlock = {
				id: `c1_${this.settings.id}`,
				type: "rows",
				variants: {
					"padding-horizontal": "px-medium",
					"bg-color": "transparent",
				},
				blocks: [
					{
						type: "text",
						variants: {
							size: "overline",
							align: this.textAlign,
						},
						text: this.settings.overline,
					},
					{
						type: "text",
						variants: {
							size: "headline",
							align: this.textAlign,
							weight: "bold",
						},
						text: this.settings.headline,
					},
					{
						type: "text",
						variants: {
							size: "subline",
							align: this.textAlign,
							weight: "bold",
						},
						text: this.settings.subline,
					},
					{
						type: "rich-text",
						variants: {
							align: this.textAlign,
						},
						richText: this.settings.bodyText,
					},
				],
			};
			const buttonContainer = this.getButtonContainer();
			if (buttonContainer) {
				contentBlock.blocks.push(buttonContainer);
			}
			response.blocks.push(contentBlock);
			return response;
		},
		getCarouselSettings() {
			const imageBlock = {
				type: "rows",
				blocks: [],
				variants: {
					"bg-color": "transparent",
				},
			};
			if (this.settings.arrayMediaSrc) {
				const carouselContainer = {
					...this.settings,
					id: `carousel_${this.settings.id}`,
					type: "container",
					variants: {
						layout: "carousel-hero",
						"child-width": "slim",
					},
					blocks: [],
				};
				for (const element of this.settings.arrayMediaSrc) {
					carouselContainer.blocks.push({
						...element,
						variants: {
							sizing: "contain",
							"bg-color": "transparent",
						},
						width: "100%",
						height: "auto",
					});
				}

				imageBlock.blocks = [carouselContainer];
			}
			return imageBlock;
		},
		getButtonContainer() {
			let response = null;
			if (
				this.settings["button-primary-url"] ||
				this.settings["button-secondary-url"] ||
				this.settings["button-tertiary-url"]
			) {
				response = {
					id: `c2_${this.settings.id}`,
					type: "container",
					variants: {
						layout: "columns",
						"bg-color": "transparent",
					},
					locked: false,
					allowTypes: ["button"],
					blocks: [],
				};
				if (this.settings["button-primary-url"]) {
					response.blocks.push({
						id: `b1_${this.settings.id}`,
						label:
							this.settings["button-primary-label"] ||
							"Primary Button Label",
						newWindow: this.settings["button-primary-newTab"],
						type: "button",
						url: this.settings["button-primary-url"],
						variants: {
							style: "primary",
						},
					});
				}
				if (this.settings["button-secondary-url"]) {
					response.blocks.push({
						id: `b2_${this.settings.id}`,
						label:
							this.settings["button-secondary-label"] ||
							"Secondary Button Label",
						newWindow: this.settings["button-secondary-newTab"],
						type: "button",
						url: this.settings["button-secondary-url"],
						variants: {
							style: "secondary",
						},
					});
				}
				if (this.settings["button-tertiary-url"]) {
					response.blocks.push({
						id: `b3_${this.settings.id}`,
						label:
							this.settings["button-tertiary-label"] ||
							"Tertiary Button Label",
						newWindow: this.settings["button-tertiary-newTab"],
						type: "button",
						url: this.settings["button-tertiary-url"],
						variants: {
							style: "tertiary",
						},
					});
				}
			}
			return response;
		},
		scrollDown() {
			if (process.client) {
				window.scroll({ top: 900, behavior: "smooth" });
			}
		},
	},
};
